import React, { useEffect, useState, useContext } from "react";
import { StyledFastChooseItems } from "./style";
import useApi from "../../hooks/useApi";
import ApiErrorForm from "../ApiErrorForm/ApiErrorForm";
import { DataContext } from "../../contexts/DataContext";
import { LayoutContext } from "../../contexts/LayoutContext";
import Card from "../CardsAlveari/Card";
import { useForm } from "react-hook-form";
import { Colors } from "../../commons/Theme";
import {
  StyledContainerCards,
} from "../Intro_v2/style.jsx";
import { TreeCard } from "../CardsChristmas/TreeCard";

const MAX_HIVES = 3;

const CardsValentine = () => {
  const {
    getHives_v2, getAddons, getCountries, getTrees,
  } = useApi();
  const { setModalContent } = useContext(LayoutContext);
  const [hives, setHives] = useState([]);
  const [tree, setTree] = useState();
  const [addons, setAddons] = useState([]);
  const [countries, setCountries] = useState();
  const { languages } = useContext(DataContext);

  const form = useForm();
  const marketCountry = form.watch("marketCountry");

  const currentLanguage = languages.filter((language) => language.isCurrent);
  const currentLanguageCode = currentLanguage ? currentLanguage[0].label : "en";

  useEffect(() => {
    if (!marketCountry) {
      return;
    }
    getHives_v2({
      page: 1,
      lang: currentLanguageCode,
      country: marketCountry,
      rare: 0,
      limitId: 1,
      honeyID: 9,
      shuffleId: 1,
      avQuantityGte: 0.5,
    }).then((results) => {
      const _hives = results.sort(() => 0.5 - Math.random());
      if (_hives.error) {
        setModalContent(<ApiErrorForm message={_hives.message} />);
      } else {
        var _hives_filtered = _hives.filter(
          (hive) => (hive.main_image !== null) && (hive.gallery.length > 0) && (hive.honey.available_plans.length > 0),
        );
        if (_hives_filtered.length <= 2) {
          _hives_filtered = _hives;
        }
        setHives(
          _hives_filtered
            .slice(0, MAX_HIVES)
            .map(e => {
              return {
                ...e,
                // main_image: "https://3bee-assets.s3.eu-south-1.amazonaws.com/christmas_cards/hive_gift_backgroud.jpg",
              };
            }),
        );
      }
    });

    getTrees({
      lang: currentLanguageCode,
      page: "0",
      country: marketCountry,
      limitId: 1,
      treeId: 12,
    }).then((results) => {
      if (results.length == 0) {
        return;
      }
      const _tree = results[0];
      setTree({
        ..._tree,
        // image_url: "https://3bee-assets.s3.eu-south-1.amazonaws.com/christmas_cards/tree_gift_backgroud.jpg",
      });
    });
  }, [
    currentLanguageCode,
    marketCountry,
    setModalContent,
    getHives_v2,
    getTrees,
  ]);

  useEffect(() => {
    getAddons({
      country: currentLanguageCode,
    }).then((_addons) => {
      if (_addons.error) {
        setModalContent(<ApiErrorForm message={_addons.message} />);
      } else {
        setAddons(_addons);
      }
    });
  }, [currentLanguageCode, setModalContent, getAddons]);

  useEffect(() => {
    getCountries().then((_countries) => {
      if (_countries.error) {
        setModalContent(<ApiErrorForm message={_countries.message} />);
      } else {
        setCountries(_countries);
        form.setValue(
          "marketCountry",
          _countries.market_countries.find(
            (e) => e.toLowerCase() === currentLanguageCode,
          ) ?? "IT",
        );
      }
    });
  }, [setModalContent, getCountries, form, currentLanguageCode]);

  if (hives.length == 0) {
    return;
  }
  
  const itemOne = currentLanguageCode == "it" && (
    <Card
      hive={hives[0]}
      hiveIndex={1}
      addons={addons}
      countries={countries}
      color={Colors.pinkText}
    />
  );

  const itemTwo = tree != null ? (
    <TreeCard 
      {...tree}
      color="#73A77B"
    /> 
  ) : hives.length > 1 ? (
    <Card
      hive={hives[1]}
      hiveIndex={1}
      addons={addons}
      countries={countries}
      color={Colors.pinkText}
    />
  ) : null;

  return (
    <>
      <StyledContainerCards>
        <StyledFastChooseItems>
          {itemOne}
          {itemTwo}
          {hives.length > 2 && (
            <Card
              hive={hives[2]}
              hiveIndex={2}
              addons={addons}
              countries={countries}
              color={Colors.pinkText}
            />
          )}
        </StyledFastChooseItems>
      </StyledContainerCards>
    </>
  );
};

export default CardsValentine;
